<template>
  <v-select
      v-model="selectedReasons"
      :label="labelString"
      :items="reasons"
      :rules="rules"
      item-text="name"
      item-value="id"
      :multiple="multiple"
      :chips="chips"
      :disabled="disabled"
      :readonly="readonly || loading"
      :loading="loading"
      class="v-select--no-chips"
      :menu-props="{ minWidth: '288px !important' }"
  ></v-select>
</template>

<script>
import { HTTP } from '@/auth'

export default {
  name: "SelectFilterWidget",
  props: {
    value: {},  
    rules: {
      type: Array,
      default: () => []
    },
    multiple: {
      type:  Boolean,
      default:  false
    },
    chips: {
      type:  Boolean,
      default:   false
    },
    disabled: {
      type:  Boolean,
      default:  false
    },
    readonly: {
      type:  Boolean,
      default:  false
    },
    label: {
      type:  String,
      default:   ''
    },
    url: {
      type:  String,
      required: true
    }
  },
  data() {
    return {
      selectedReasons: this.value,
      reasons: [],
      loading:  false
    }
  },
  watch: {
    selectedReasons: function (val) {
      this.$emit('input', val)
    }
  },
  async created() {
    await this.loadData()
  },
  computed: {
    labelString:  function () {
     return this.label !== '' ? this.label  : this.$t('components.select-filter-widget.label')
    } 
  },
  methods: {
    async loadData() {
      this.loading = true
      let response = await HTTP.get(this.url)
      this.reasons = response.data.data ? response.data.data : response.data
      this.loading = false
    },
  },
}
</script>