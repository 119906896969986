<template>
  <div class="has-hero-container">
    <div>
		  <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

		  <div class="sp-hero sp-bg-light-blue">

			  <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

          <app-filter-apply-button
            v-if="filterStore.hasChanges"
            @click="applyFilter(); options.page = 1"
          ></app-filter-apply-button>

          <v-slide-group show-arrows>
            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="t('filter.date-search-from')"
                  v-model="filterStore.filter.dateStart"
                  id="dateStart"
                  :clearable="true"
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="t('filter.date-search-to')"
                  v-model="filterStore.filter.dateEnd"
                  id="dateEnd"
                  :clearable="true"
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <content-filter-checklist
                      v-model="filterStore.filter.selectedChecklist"
                      :label="t('filter.checklist-search')"
                      :get-search-url="getChecklistsSearchUrl"
                      :preselected="filterStore.filter.selectedChecklist ? filterStore.filter.selectedChecklist : null"
                      clearable
                      append-icon="mdi-magnify"
                    ></content-filter-checklist>
                  </div>
                </div>
              </div>
					  </v-slide-item>

            <v-slide-item>
              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <content-filter-checklist-customer
                      v-model="filterStore.filter.selectedCustomer"
                      :label="t('filter.customer-search')"
                      :get-search-url="getCustomersSearchUrl"
                      :preselected="filterStore.filter.selectedCustomer ? filterStore.filter.selectedCustomer : null"
                      clearable
                      append-icon="mdi-magnify"
                    ></content-filter-checklist-customer>
                  </div>
                </div>
              </div>
					  </v-slide-item>

            <v-slide-item>
              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <user-filter-widget
                      v-model="filterStore.filter.selectedUser"
                      :label="t('filter.user-search')"
                      clearable
                      append-icon="mdi-magnify"
                      :includeInactive="true"
                    ></user-filter-widget>
                  </div>
                </div>
              </div>
            </v-slide-item>
          </v-slide-group>

        </v-container>

		  </div>

      <v-container fluid>

        <v-row class="data-table-toolbar"></v-row>

        <v-row v-if="dataLoaded && reports.data.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
            <loading v-if="loading"></loading>
          </div>
        </v-row>

        <v-row v-else>

          <v-data-table
            :headers="headers"
            :items="reports.data"
            :options.sync="options"
            :server-items-length="dataTotal"
            :loading="loading"
            class="elevation-0"
            :sort-by.sync="filterStore.table.sortBy"
            :sort-desc.sync="filterStore.table.sortDesc"
            :page.sync="filterStore.table.currentPage"
            :items-per-page.sync="filterStore.table.itemsPerPage"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.report`]="{ item }">
              <span class="ellipsis"><b>{{ item.name }}</b></span>
              <br>
              <span>{{ formatDateTime(item.created_at) }}</span>
            </template>

            <template v-slot:[`item.customer`]="{ item }">
              <span class="ellipsis">{{ item.checklist.customer.name }}</span>
            </template>

            <template v-slot:[`item.user`]="{ item }">
              <span class="ellipsis">{{ item.coordinator.display_name }}</span>
            </template>

            <template v-slot:[`item.tasks`]="{ item }">
              {{ item.tasks }}
            </template>

            <template v-slot:[`item.rating`]="{ item }">
              <div class="overall-rating-wrapper" :class="getRatingClass(item)">
                <div class="overall-rating">
                  <div class="overall-rating-indicator" :style="{ width: item.overallResult + '%' }"></div>
                </div>
                <div class="overall-rating-text">{{ item.overallResult }}%</div>
              </div>
            </template>

            <template v-slot:[`item.view`]="{ item }">
              <router-link :to="{ name: 'ReportsShow', params: { id: item.id } }" class="edit-row-table">
                <v-icon class="edit-row-button">
                  mdi-eye-outline
                </v-icon>
              </router-link>
            </template>

            <template v-slot:[`item.pdf`]="{ item }">
              <div class="edit-row-table">
                <v-icon
                  class="edit-row-button"
                  @click="downloadPdf(item.id, item.filename)"
                >
                  picture_as_pdf
                </v-icon>
              </div>
            </template>
          </v-data-table>

        </v-row>

		  </v-container>

    </div>
  </div>

</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import ContentFilterChecklistCustomer from '@/components/filter-components/ContentFilterChecklistCustomer'
import UserFilterWidget from '@/components/filter-components/UserFilterWidget'
import ContentFilterChecklist from '@/components/filter-components/ContentFilterChecklist'
import { useQCReportsFilterStore } from '@/stores/QCReportsFilterStore'

export default {
	name: 'ReportsIndex',
  components: {
    toolbar,
    loading,
    AppFilterApplyButton,
    AppFilterDatePicker,
    ContentFilterChecklistCustomer,
    UserFilterWidget,
    ContentFilterChecklist
  },
  setup() {
    const filterStore = useQCReportsFilterStore()
    return { filterStore }
  },
  data () {
    return {
      loading: false,
      reports: {
        page: 1,
        current_page: 1,
        last_page: 1,
        total: null,
        data: []
      },
      options: {},
      dataLoaded: false,
      error: false,
      dataTotal: 0
    }
  },
  created() {
    this.applyFilter(false)
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.headers.report'),
          align: 'start',
          sortable: true,
          value: 'report'
        },
        {
          text: this.t('table.headers.customer'),
          align: 'start',
          sortable: false,
          value: 'customer',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.headers.user'),
          align: 'start',
          sortable: false,
          value: 'user',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.headers.tasks'),
          align: 'start',
          sortable: false,
          value: 'tasks',
          width: 75,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.headers.rating'),
          align: 'start',
          sortable: false,
          value: 'rating',
          width: 200,
          cellClass: 'text-middle text-align-center'
        },
        {
          text: this.t('table.headers.view'),
          align: 'start',
          sortable: false,
          value: 'view',
          width: 75,
          cellClass: 'text-middle text-align-center'
        },
        {
          text: this.t('table.headers.pdf'),
          align: 'start',
          sortable: false,
          value: 'pdf',
          width: 75,
          cellClass: 'text-middle text-align-center'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getReports()
      },
      deep: true,
    }
  },
  methods: {
    getRatingClass(item) {
      let overallResult = parseFloat(item.overallResult)
      if (overallResult >= 80) {
        return 'rating-good'
      }
      else if(overallResult >= 60){
        return 'rating-medium'
      }
      else {
        return 'rating-bad'
      }
    },
    t: function (key) {
      return this.$t('qc-reports.list.' + key)
    },
    reload () {
      this.$nextTick(function () {
        this.getReports()
      }.bind(this))
    },
    applyFilter(doReload = true) {
      this.filterStore.filterApplied()
      if (doReload) {
        this.reload()
      }
    },
    prepareUrl() {
      let queryStringParts = []
      if (this.filterStore.filter.dateStart) {
        queryStringParts.push('dateStart=' + this.filterStore.filter.dateStart)
      }
      if (this.filterStore.filter.dateEnd) {
        queryStringParts.push('dateEnd=' + this.filterStore.filter.dateEnd)
      }
      if (this.filterStore.filter.selectedUser) {
        queryStringParts.push('u=' + this.filterStore.filter.selectedUser.id)
      }
      if (this.filterStore.filter.selectedCustomer) {
        queryStringParts.push('c=' + this.filterStore.filter.selectedCustomer.id)
      }
      if (this.filterStore.filter.selectedChecklist) {
        queryStringParts.push('l=' + this.filterStore.filter.selectedChecklist.id)
      }
      return '?' + queryStringParts.join('&')
    },
    getReports () {
      this.loading = true
      let url = this.prepareUrl()
      HTTP.get('reports' + url, { params: this.options })
        .then(function (response) {
          this.reports.data = response.data.data
          this.reports.last_page = response.data.last_page
          this.reports.current_page = response.data.current_page
          this.reports.total = response.data.total
          this.dataTotal = response.data.total
          this.error = false
          this.dataLoaded = true
          this.loading = false
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.loading = false
        }
      }.bind(this))
    },
    getCustomersSearchUrl(val) {
        return 'checklists/getCustomers?q=' + val
    },
    getChecklistsSearchUrl(val) {
        return 'checklists?q=' + val
    }
  }
}
</script>

<style scoped lang="scss">
.overall-rating-wrapper{
  gap: 20px;
  display: flex;
  align-items: center;
}

.overall-rating {
  position: relative;
  height: 20px;
  width: 144px;
  border-radius: 10px;
  text-align: center;
}

.overall-rating-indicator {
  position: absolute;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 10px;
}

.overall-rating-text {
  position: relative;
  font-weight: bold;
}

.rating-good {

  .overall-rating{
    background: #E3F5DC;

    .overall-rating-indicator {
      background: #48962D;
    }
  }

  .overall-rating-text{
    color: #48962D;
  }
}

.rating-medium {

  .overall-rating{
    background: #E3F5DC;

    .overall-rating-indicator {
      background: #E88100;
    }
  }

  .overall-rating-text{
    color: #E88100;
  }
}

.rating-bad {

  .overall-rating{
    background: #F9D4D6;

    .overall-rating-indicator {
      background: #E30613;
    }
  }

  .overall-rating-text{
    color: #E30613;
  }
}

.edit-row-button {
  cursor: pointer;
}
</style>
