const themesZwe = {
  themes: {
    light: {
      primary: '#3AA23A',
      secondary: '#E1F1E1',
      success: '#71AE5C',
      warning: '#E30613',
      error: '#E30613',
      buttons:'#3AA23A'
    }
  },
}

export default themesZwe