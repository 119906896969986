<template>
    <div class="has-hero-container">
        <div>
            <toolbar :dataLoaded="true">{{ $t('tasks.list.title') }}</toolbar>

            <div class="sp-hero sp-bg-light-blue">

                <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

                    <AppFilterApplyButton
                        v-if="filterStore.hasChanges"
                        @click="applyFilter(); options.page = 1"
                    ></AppFilterApplyButton>

                    <v-slide-group show-arrows>
                        <v-slide-item>
                            <div class="filter-item">
                                <app-filter-date-picker
                                    :filterLabel="$t('tasks.list.filter.from')"
                                    v-model="filterStore.filter.dateStart"
                                    id="dateStart"
                                    data-cy="dateStart"
                                    clearable
                                ></app-filter-date-picker>
                            </div>
                        </v-slide-item>

                        <v-slide-item>
                            <div class="filter-item">
                                <app-filter-date-picker
                                    :filterLabel="$t('tasks.list.filter.to')"
                                    v-model="filterStore.filter.dateEnd"
                                    data-cy="dateEnd"
                                    id="dateEnd"     
                                ></app-filter-date-picker>
                            </div>
                        </v-slide-item>

                        <v-slide-item v-if="moduleEnabled('customers')">
                            <div class="filter-search-list">
                                <div class="filter-item">
                                    <div class="filter-item-wrapper">
                                        <content-filter-customer
                                            :preselected="filterStore.filter.selectedCustomer"
                                            v-model="filterStore.filter.selectedCustomer"
                                            data-cy="selectedCustomer"
                                            :get-search-url="getCustomerSearchUrl"
                                        ></content-filter-customer>
                                    </div>
                                </div>
                            </div>
                        </v-slide-item>

                        <v-slide-item>
                            <div class="filter-search-list">
                                <div class="filter-item">
                                    <div class="filter-item-wrapper">
                                        <user-filter-widget
                                            v-model="filterStore.filter.selectedUser"
                                            :includeInactive="true"
                                        ></user-filter-widget>
                                    </div>
                                </div>
                            </div>
                        </v-slide-item>

                        <v-slide-item>
                            <div class="filter-item filter-status-filter">
                                <tasks-categories-filter
                                    v-model="filterStore.filter.categories"
                                ></tasks-categories-filter>
                            </div>
                        </v-slide-item>

                        <v-slide-item>
                            <div class="filter-item filter-status-filter">
                                <select-status-filter-widget 
                                v-model="filterStore.filter.status"
                                :multiple="true"
                                :filter-list="['open', 'done', 'overdue']"
                                ></select-status-filter-widget>
                            </div>
                        </v-slide-item>
                    </v-slide-group>

                </v-container>

            </div>

            <v-container fluid>

                <v-row class="data-table-toolbar">
                    <v-spacer></v-spacer>
                    <div v-show="dataLoaded && taskItems.length > 0">
                        <v-btn
                            outlined
                            color="primary"
                            elevation="0"
                            class="icon-left"
                            :disabled="!readyToDownload"
                            :loading="isDownloadingExport"
                            data-cy="excelButton"
                            @click="exportExcel()"
                        >
                            <v-icon>mdi-download</v-icon>
                            {{ $t('tasks.list.excel-export') }}
                        </v-btn>
                    </div>
                    <div>
                        <v-btn
                            elevation="0"
                            color="primary"
                            class="icon-left"
                            data-cy="createButton"
                            :to="{ path: '/tasks/create'}"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('tasks.list.create') }}
                        </v-btn>
                    </div>
                </v-row>

                <v-row v-if="dataLoaded && taskItems.length === 0">
                    <div class="empty-datatable">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                        {{ $t('tasks.list.no-data') }}
                    </div>
                </v-row>

                <v-row v-else>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="taskItems"
                        :options.sync="options"
                        :server-items-length="totalTasks"
                        :loading="loading"
                        class="elevation-0"
                        :sort-by.sync="filterStore.table.sortBy"
                        :sort-desc.sync="filterStore.table.sortDesc"
                        :page.sync="filterStore.table.currentPage"
                        :items-per-page.sync="filterStore.table.itemsPerPage"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-minus',
                            nextIcon: 'mdi-plus',
                            'items-per-page-options': [25, 50, 100, 200],
                            pageText: '{0}-{1} von {2}',
                            'items-per-page-text': $t('tasks.list.table.items-per-page')
                        }"
                    >
                        <template v-slot:[`item.title`]="{ item }">
                            <span class="ellipsis" v-if="item.title"><b>{{ item.title }}</b></span>
                            <span class="ellipsis">{{ item.category.name }}</span>
                        </template>

                        <template v-slot:[`item.customer`]="{ item }">
                            <span class="ellipsis" v-if="item.customer">{{ getCustomerName(item.customer) }}</span>
                        </template>

                        <template v-slot:[`item.author`]="{ item }">
                            <span class="ellipsis">{{ item.author.firstname }}</span>
                            <span class="ellipsis">{{ item.author.name }}</span>
                        </template>

                        <template v-slot:[`item.assignee`]="{ item }">
                            <span class="ellipsis">{{ item.assignee.firstname }}</span>
                            <span class="ellipsis">{{ item.assignee.name }}</span>
                        </template>

                        <template v-slot:[`item.created_at`]="{ item }">
                            {{ item.created_at | momentDate }}
                        </template>

                        <template v-slot:[`item.due_date`]="{ item }">
                            <div :class="{ 'task-overdue' : item.overdue }">
                                <span v-if="item.status === 'done'">{{ item.completed_at | momentDate }}</span>
                                <span v-if="item.status === 'done'"><br>{{ item.completed_at | momentTime }} Uhr</span>
                                <span v-if="item.status === 'open' || item.status === 'overdue'">{{ item.due_date | momentDate }}</span>
                                <span v-if="item.status === 'open' || item.status === 'overdue'"><br>{{ item.due_date | momentTime }} Uhr</span>
                            </div>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <status-icon-widget :status="item.status" :hide-text="false"/>
                        </template>

                        <template v-slot:[`item.edit`]="{ item }">
                            <router-link :to="{ path: '/tasks/' + item.id }" class="edit-row-table" data-cy="editButton">
                                <v-icon
                                    small
                                    class="edit-row-button"
                                >
                                    edit
                                </v-icon>
                            </router-link>
                        </template>
                    </v-data-table>

                </v-row>

            </v-container>

        </div>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus.js'
import { hasModule } from '@/services/LicenseService'
import toolbar from '@/components/layouts/Navigation'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import TasksCategoriesFilter from '@/components/filter-components/TasksCategoriesFilter'
import moment from 'moment'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import UserFilterWidget from '@/components/filter-components/UserFilterWidget'
import ContentFilterCustomer from '@/components/filter-components/ContentFilterCustomer'
import { useTaskFilterStore } from '@/stores/TaskFilterStore'
import StatusIconWidget from '@/components/layouts/StatusIconWidget'
import SelectStatusFilterWidget from '@/components/filter-components/SelectStatusFilterWidget'


export default {
    name: 'TasksIndex',
    components: {
        ContentFilterCustomer,
        SelectStatusFilterWidget,
        UserFilterWidget,
        AppFilterApplyButton,
        StatusIconWidget,
        toolbar,
        AppFilterDatePicker,
        AppFilterSearchField,
        TasksCategoriesFilter
    },
    setup() {
        const filterStore = useTaskFilterStore()
        return { filterStore }
    },
    data() {
        return {
            loading: false,
            isDownloadingExport: false,
            item: null,
            dataLoaded: false,
            message: null,
            error: false,
            cancelHTTP: null,
            errorCancel: null,
            totalTasks: 0,
            taskItems: [],
            options: {}
        }
    },
    filters: {
        momentDate: function (date) {
            return moment(date).format('DD.MM.YYYY')
        },
        momentTime: function (date) {
            return moment(date).format('HH:mm')
        }
    },
    created() {
        if (!this.moduleEnabled('customers')) {
            this.headers.splice(1, 1)
        }
        EventBus.$on('http-error', function (msg) {
            this.message = msg
            this.isDownloadingExport = false
            this.error = true
        }.bind(this))
    },
    beforeDestroy() {
        EventBus.$off('http-error')
    },
    watch: {
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        }
    },
    computed: {
        readyToDownload: function () {
            return this.dataLoaded && !this.isDownloadingExport
        },
        headers() {
            return [
                {
                    text: this.$t('tasks.list.table.name'),
                    align: 'start',
                    sortable: true,
                    value: 'title',
                    width: 300
                },
                {
                    text: this.$t('tasks.list.table.customer'),
                    value: 'customer',
                    sortable: true,
                    width: 200
                },
                {
                    text: this.$t('tasks.list.table.author'),
                    value: 'author',
                    sortable: true,
                    width: 150
                },
                {
                    text: this.$t('tasks.list.table.assignee'),
                    value: 'assignee',
                    sortable: true,
                    width: 150
                },
                {
                    text: this.$t('tasks.list.table.created-at'),
                    value: 'created_at',
                    sortable: true,
                    width: 150
                },
                {
                    text: this.$t('tasks.list.table.due'),
                    value: 'due_date',
                    sortable: true,
                    width: 150
                },
                {
                    text: this.$t('tasks.list.table.status'),
                    value: 'status',
                    width: 100,
                    align: 'start',
                    sortable: false,
                    cellClass: 'text-middle'
                },
                {
                    text: this.$t('tasks.list.table.edit'),
                    value: 'edit',
                    sortable: false,
                    align: 'center',
                    width: 150
                }
            ]
        }
    },
    methods: {
        moment: function () {
            return moment()
        },
        reload() {
            this.taskItems = []
            this.getDataFromApi()
        },
        getCustomerName(customer) {
            if (customer.name) {
                return customer.name
            } else {
                return customer.firstname + ' ' + customer.lastname
            }
        },
        applyFilter() {
            this.filterStore.filterApplied()
            this.reload()
        },
        moduleEnabled(string) {
            return hasModule(string)
        },
        exportExcel() {
            this.isDownloadingExport = true

            const { sortBy, sortDesc, page, itemsPerPage } = this.options

            let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

            HTTP.post('dataexport/excel/tasks' + url, {}, {responseType: 'arraybuffer'}).then(function (response) {
                let headers = response.headers
                let blob = new Blob([response.data], {type: headers['content-type']})
                let link = document.createElement('a')
                link.style = 'display: none'
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Pendenzen_' + moment().format('DDMMYYYY-HHmm') + '.xlsx'
                document.body.appendChild(link)
                link.click()
                setTimeout(function () {
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(blob)
                }, 100)
                this.isDownloadingExport = false
            }.bind(this))
        },
        prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
            let filter = this.filterStore.filter
            let queryStringParts = []
            if (filter.dateStart) {
                queryStringParts.push('dateStart=' + moment(filter.dateStart).format('YYYY-MM-DD'))
            }
            if (filter.dateEnd) {
                queryStringParts.push('dateEnd=' + moment(filter.dateEnd).format('YYYY-MM-DD'))
            }
            if (filter.selectedUser) {
                queryStringParts.push('q=' + filter.selectedUser.id)
            }
            if (filter.selectedCustomer) {
                queryStringParts.push('c=' + filter.selectedCustomer.id)
            }
            if (this.filterStore.filter.categories.length > 0) {
                queryStringParts.push('cats=' + this.filterStore.filter.categories)
            }
            if (filter.status.length > 0) {
                queryStringParts.push('st=' + filter.status.join(',').toUpperCase())
            }
            queryStringParts.push('sortBy=' + (typeof sortBy !== "undefined" && sortBy[0] ? sortBy[0] : ''))
            let sort = typeof sortDesc !== "undefined" && sortDesc[0] ? 'desc' : 'asc'
            queryStringParts.push('sortDesc=' + sort)
            page = page ? page : 1
            queryStringParts.push('page=' + page)
            queryStringParts.push('itemsPerPage=' + (typeof itemsPerPage !== "undefined" ? itemsPerPage : 25))
            return '?' + queryStringParts.join('&')
        },
        getDataFromApi() {
            this.loading = true
            this.dataLoaded = false
            this.apiCall().then(data => {
                this.taskItems = data.taskItems
                this.totalTasks = data.total
                this.loading = false
                this.dataLoaded = true
            })
        },
        apiCall() {
            return new Promise((resolve, reject) => {
                const {sortBy, sortDesc, page, itemsPerPage} = this.options

                let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

                HTTP.get('tasks' + url).then(function (response) {
                    let taskItems = response.data.data
                    taskItems.forEach((element) => {
                        element.status = element.status.toLowerCase()
                        element.overdue = element.status === 'open' && moment(element.due_date) < moment()
                        if (element.overdue) {
                            element.status = 'overdue'
                        } 
                    })
                    let total = response.data.total

                    resolve({
                        taskItems,
                        total,
                    })
                }.bind(this)).catch(function (error) {
                    if (!error.status) {
                        this.error = true
                    }
                }.bind(this))

            })
        },
        getCustomerSearchUrl(val) {
            return 'tasks/customers?q=' + val
        }
    }
}
</script>

<style lang="scss" scoped>
.ellipsis {
  display: block;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 400px;
}

.task-overdue {
  color: var(--v-error-base);
}
</style>

