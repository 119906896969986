<template>
  <div class="has-hero-container">

    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <div class="sp-hero sp-bg-light-blue">
      <v-container fluid class="hero-filter">
        <v-text-field
          class="pl-4"
          elevation="0"
          :label="t('question-search')"
          v-model="questionSearchValue"
          append-icon="mdi-magnify"
          filled
          hide-details="auto"
        ></v-text-field>
      </v-container>
    </div>

    <v-container fluid>

      <v-row class="data-table-toolbar">
        <v-spacer></v-spacer>
        <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            :to="{ name: 'ObjectControlElementsCreate'}"
        >
          <v-icon>mdi-plus</v-icon>
          {{ t('create') }}
        </v-btn>
      </v-row>

      <v-row v-if="dataLoaded && elements.length === 0">
        <div class="empty-datatable">
          <v-icon>mdi-alert-circle-outline</v-icon>
          {{ t('no-data') }}
        </div>
      </v-row>

      <v-row v-else>

        <v-data-table
            :headers="headers"
            :items="filteredElements"
            :server-items-length="filteredElements.length"
            :loading="loading"
            class="elevation-0"
            hide-default-footer           
        >
          <template #body="props">
            <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
            <draggable
              v-else
              :list="props.items"
              tag="tbody"
              :move="onMoveCallback"
              :clone="onCloneCallback"
              @end="onDropCallback(props.items)"
            >
              <data-table-object-control-element-row-handler
                v-for="(item, index) in props.items"
                :row-index="index"
                :item="item"
                :headers="headers"
              ></data-table-object-control-element-row-handler>  
            </draggable>
          </template>
        </v-data-table>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation.vue'
import DataTableObjectControlElementRowHandler from '@/components/DataTableObjectControlElementRowHandler'
import draggable from 'vuedraggable'

export default {
  name: 'ObjectControlElementsIndex',
  components: {
    toolbar,
    DataTableObjectControlElementRowHandler,
    draggable
  },
  data() {
    return {
      elements: [],
      dataLoaded: false,
      loading: false,
      error: false,
      questionSearchValue: '',
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'drag',
          width: 30
        },
        {
          text: this.t('table.question'),
          align: 'start',
          sortable: false,
          value: 'question'
        },
        {
          text: this.t('table.status'),
          align: 'start',
          sortable: false,
          value: 'status',
          width: 150
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 75
        }
      ]
    },
    filteredElements() {
      if (this.questionSearchValue !== '') {
        return this.elements.filter((el) =>
          el.question.toLowerCase().includes(this.questionSearchValue.toLowerCase())
        )
      } else {
        return this.elements
      }
    },
  },
  created() {
    this.getElements()
  },
  methods: {
    t: function (key) {
      return this.$t('object-control.elements.list.' + key)
    },
    getElements() {
      this.loading = true
      HTTP.get('object_control_elements').then(function (response) {
        this.elements = response.data
        this.dataLoaded = true
        this.loading = false
        this.error = false
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
    onCloneCallback(item) {
      const cloneMe = JSON.parse(JSON.stringify(item))
      return cloneMe
    },
    onMoveCallback(evt) {
        const item = evt.draggedContext.element
        const itemIdx = evt.draggedContext.futureIndex
        if (item.locked) {
          return false
        }
        return true
      },
    async onDropCallback(items) {
        await HTTP.post('object_control_elements_sort', items).then(function () {
          this.$root.infoNotification.showMessage(this.$t('object-control.elements.list.sorting-sucessful'))
        }.bind(this))
    }
  }
}
</script>

<style scoped lang="scss">
.table-loader {
  padding: 0 !important;
  height: auto !important;
}

.sp-hero {
  height: 124px !important;

  .v-input {
    width: 320px;
    min-width: 320px;
  }
}
</style>
