<template>
  <div>
    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <v-container fluid class="edit-screen">

      <loading :error="error" :dataLoaded="dataLoaded"></loading>
    
      <v-row v-if="dataLoaded" class="settings-wrapper">

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('list.title') }}</v-card-title>
          <v-card-text>
            <div class="button-wrapper">
              <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                data-cy="createButton"
                @click="openSettingDialog(null)"
                :disabled="saving || loading"
              >
                <v-icon>mdi-plus</v-icon>
                {{ t('detail.create.title') }}
              </v-btn>
            </div>

            <v-data-table
              v-if="dataLoaded"
              :headers="headers"
              :items="contacts"
              :items-per-page="99"
              class="elevation-0"
              hide-default-footer
            >
              <template #body="props">
                <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
                <draggable
                  v-else
                  v-model="contacts"
                  tag="tbody"
                >
                  <data-table-emergency-row-handler
                    v-for="(item, index) in props.items"
                    :row-index="index"
                    :item="item"
                    :headers="headers"
                  ></data-table-emergency-row-handler> 
                </draggable>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-row>

    </v-container>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-btn
              color="primary"
              text
              class="icon-left"
              @click="reset()"
              :disabled="saving"
            >
              <v-icon>mdi-reload</v-icon>
              {{ t('detail.update') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                class="icon-left"
                @click="saveSettings()"
                :disabled="!hasChanges || loading || !dataLoaded"
                :loading="saving"
            >
              <v-icon>mdi-check</v-icon>
              {{ t('detail.save') }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </transition>

    <v-dialog
      v-model="settingsDialog"
      content-class="settings-dialog"
      width="600"
      persistent
    >
      <v-card v-if="settingsDialog">
        <v-card-title>
          {{ editItem.id ? t('dialog.edit-title') : t('dialog.add-title') }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-on:submit.prevent="editItem.id ? editSettings() : addSettings()">
            <v-text-field
              :label="t( 'detail.details.title') + '*'"
              v-model="editItem.title"
              counter="40"
              filled
              :rules="[
                () => !!editItem.title || t( 'validation.error-title-required'),
                () => (editItem.title && editItem.title.length <= 40) || t( 'validation.error-max-title-40')
              ]"
            ></v-text-field>

            <v-text-field
              :label="t( 'detail.details.description')"
              filled
              v-model="editItem.description"
            ></v-text-field>

            <v-text-field
              :label="t( 'detail.details.contact-phone') + '*'"
              filled
              v-model="editItem.phone_number"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            elevation="0"
            color="primary"
            text
            class="icon-left px-4"
            @click="closeSettingsDialog()"
          >
            <v-icon>mdi-close</v-icon>
            {{ t( 'detail.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left px-4"
            @click="editItem.id ? editSettings() : addSettings()"
          >
            <v-icon>mdi-check</v-icon>
            {{ editItem.id ? t('detail.update') : t('detail.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus.js'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import DataTableEmergencyRowHandler from '@/components/DataTableEmergencyRowHandler'
import draggable from 'vuedraggable'

export default {
  name: 'EmergencyContacts',
  components: {
    toolbar,
    loading,
    DataTableEmergencyRowHandler,
    draggable
  },
  data() {
    return {
      contacts: [],
      dataOriginal: {},
      dataLoaded: false,
      loading: false,
      saving: false,
      error: false,
      settingsDialog: false,
      editItem: null,
      editItemIndex: null,
      rules: {
        required: value => !!value || this.t('validation.contact-phone-required'),
      },
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'drag',
          width: 30
        },
        {
          text: this.t('title'),
          align: 'start',
          sortable: false,
          value: 'title'
        },
        {
          text: this.t('detail.details.contact-phone'),
          align: 'start',
          sortable: false,
          value: 'phone_number',
          width: 175
        },
        {
          text: this.t('detail.edit.title'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 120
        },
        {
          text: '',
          value: 'remove',
          sortable: false,
          align: 'center',
          width: 100,
        }
      ]
    },
    hasChanges() {
      return JSON.stringify(this.contacts) !== JSON.stringify(this.dataOriginal)
    },
  },
  created() {
    this.getSettings()
    EventBus.$on('ec-open-settings-dialog', (index, item) => {
      this.openSettingDialog(item, index)
      this.settingsDialog = true
    })
    EventBus.$on('ec-remove-item', (index) => {
      this.removeSetting(index)
    })
  },
  methods: {
    t: function (key) {
      return this.$t('emergency-contacts.' + key);
    },
    getRatings() {
      return new Promise(function(resolve, reject) {
        HTTP.get('emergency-contacts').then((response) => {
          resolve({
            'data': response.data
          })
        }).catch((error) => {
          reject(error)
        })
      }.bind(this))  
    },
    getSettings() {
      this.loading = true

      Promise.allSettled([
        this.getRatings()      
      ]).then(function(responses) {

        let errors = []
        responses.forEach((response) => {
          if (response.status == 'fulfilled') {
            this.contacts = response.value.data
          }
          if (response.status == 'rejected') {
            errors.push(this.t('request-get-error'))
          }
        })

        if (errors.length > 0) {
          this.$root.infoNotification.showMessage(errors)
        }

        this.dataOriginal = JSON.parse(JSON.stringify(this.contacts))

        this.dataLoaded = true
        this.loading = false
        this.error = false

      }.bind(this)).finally(function() {
        this.saving = false
      }.bind(this))
    },
    saveRatings() {
      return new Promise(function(resolve, reject) {
        HTTP.post('emergency-contacts', this.contacts).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        }) 
      }.bind(this))
    },
    saveSettings() {
      this.saving = true

      Promise.allSettled([
        this.saveRatings()
      ]).then(function(responses) {

        let errors = []
        responses.forEach((response) => {
          if (response.status == 'rejected') {
            errors.push(this.t('request-post-error'))
          }
        })

        if (errors.length > 0) {
          this.$root.infoNotification.showMessage(errors, true)
        } else {
          this.$root.infoNotification.showMessage(this.t('success-msg-saved'), false)
        }
        
        this.getSettings()

      }.bind(this)).finally(function() {
        this.saving = false
      }.bind(this))
    },
    reset() {
      this.dataLoaded = false
      this.contacts = []
      this.getSettings()
    },
    openSettingDialog(item, index) {
      if (item) {
        this.editItem = JSON.parse(JSON.stringify(item))
        this.editItemIndex = index
      } else {
        this.editItem = {
          id: null,
          title: '',
          description: '',
          phone_number: ''
        }
      }
      this.settingsDialog = true
    },
    closeSettingsDialog() {
      this.settingsDialog = false
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.editItem = null
        this.editItemIndex = null
      })
    },
    addSettings() {
      if (this.$refs.form.validate()) {
        this.contacts.push({
          id: -1,
          title: this.editItem.title,
          description: this.editItem.description,
          phone_number: this.editItem.phone_number
        })
        this.closeSettingsDialog()
      }
    },
    editSettings() {
      if (this.$refs.form.validate()) {
        Object.assign(this.contacts[this.editItemIndex], this.editItem)
        this.closeSettingsDialog()
      }
    },
    removeSetting(index) {
      this.contacts.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.table-loader {
  padding: 0 !important;
  height: auto !important;
}

.edit-row-table {
  cursor: pointer;
}

.apply-setting {
  color: #FFF !important;
  background: #FF5252 !important;
}

.settings-wrapper {
  justify-content: space-between;

  & > .v-card {
      width: calc(66.66% - 16px);
  }

  .v-data-table {
      margin-bottom: 0;
  }
}

.qc-validation-message {
  float: left;
  padding-top: 8px;
  font-weight: bold;
  color: var(--v-error-base);
}

.button-wrapper {
  text-align: right;
  padding-bottom: 16px;
}
</style>
