<template>
    <div class="has-hero-container">
        <div>
            <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

            <div class="sp-hero sp-bg-light-blue">
                <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

                    <app-filter-apply-button
                        v-if="filterStore.hasChanges"
                        @click="applyFilter(); options.page = 1"
                    ></app-filter-apply-button>

                    <v-slide-group show-arrows>
                        <v-slide-item>
                            <div class="filter-item">
                                <app-filter-date-picker
                                    :filterLabel="$t('components.app-filter-date-picker.label.startdate')"
                                    v-model="filterStore.filter.dateStart"
                                    id="dateStart"
                                    clearable
                                ></app-filter-date-picker>
                            </div>
                        </v-slide-item>

                        <v-slide-item>
                            <div class="filter-item">
                                <app-filter-date-picker
                                    :filterLabel="$t('components.app-filter-date-picker.label.enddate')"
                                    v-model="filterStore.filter.dateEnd"
                                    id="dateEnd"
                                    clearable
                                ></app-filter-date-picker>
                            </div>
                        </v-slide-item>

                        <v-slide-item>
                            <div class="filter-search-list">
                                <div class="filter-item">
                                    <div class="filter-item-wrapper">
                                        <user-filter-widget
                                            v-model="filterStore.filter.user"
                                            :preselected="filterStore.appliedFilter.user"
                                            :include-inactive="false"
                                        ></user-filter-widget>
                                    </div>
                                </div>
                            </div>
                        </v-slide-item>

                        <v-slide-item>
                            <div class="filter-search-list">
                                <div class="filter-item">
                                    <div class="filter-item-wrapper">
                                        <app-filter-search-field
                                            v-model="filterStore.filter.searchTerm"
                                            :filterLabel="t('filter.content-search')"
                                            clearable
                                            append-icon="mdi-magnify"									
                                        ></app-filter-search-field>
                                    </div>
                                </div>
                            </div>  
                        </v-slide-item>

                        <v-slide-item>
                            <div class="filter-item filter-status-filter">
                                <select-status-filter-widget 
                                    v-model="filterStore.filter.status"
                                    :multiple="true"
                                    :filter-list="['open', 'approved', 'declined']"
                                ></select-status-filter-widget>
                            </div>
                        </v-slide-item>
                    </v-slide-group>

                </v-container>
            </div>

            <v-container fluid>

                <v-row class="data-table-toolbar"></v-row>

                <v-row v-if="dataLoaded && items.length === 0">
                  <div class="empty-datatable">
                    <v-icon>mdi-alert-circle-outline</v-icon>
                    {{ t('no-data') }}
                  </div>
                </v-row>

                <v-row v-else>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        :options.sync="options"
                        :server-items-length="totalItems"
                        :loading="loading"
                        class="elevation-0"
                        :multi-sort="false"
                        :must-sort="true"
                        :sort-by.sync="filterStore.table.sortBy"
                        :sort-desc.sync="filterStore.table.sortDesc"
                        :page.sync="filterStore.table.currentPage"
                        :items-per-page.sync="filterStore.table.itemsPerPage"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-minus',
                            nextIcon: 'mdi-plus',
                            'items-per-page-options': [25, 50, 100, 200],
                            pageText: '{0}-{1} von {2}',
                            'items-per-page-text': t('table.items-per-page')
                        }"
                    >
                        <template v-slot:[`item.subject`]="{ item }">
                            <div class="ellipsis-wrapper">
                                <span class="ellipsis">{{ item.subject }}</span>       
                            </div>  
                        </template>
                        
                        <template v-slot:[`item.author_name`]="{ item }">
                            <span v-if="item.author_name === ''" class="anonymous">{{ t('anonymous') }}</span>
                            <span v-else>{{ item.author_name }}</span>
                        </template>
                        
                        <template v-slot:[`item.created_at`]="{ item }">
                            <span>{{ formatDate(item.created_at) }}</span>
                        </template>
                        
                        <template v-slot:[`item.status`]="{ item }">
                            <status-icon-widget :status="item.status" :hide-text="false"/>
                        </template>

                        <template v-slot:[`item.edit`]="{ item }">
                            <router-link :to="{ path: '/ideabox/' + item.id }" class="edit-row-table" data-cy="editButton">
                                <v-icon
                                    small
                                    class="edit-row-button"
                                >
                                    edit
                                </v-icon>
                            </router-link>
                        </template>
                    </v-data-table>
                </v-row>

            </v-container>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import ContentFilterEmployee from '@/components/filter-components/ContentFilterEmployee'
import UserFilterWidget from '@/components/filter-components/UserFilterWidget'
import SelectStatusFilterWidget from '@/components/filter-components/SelectStatusFilterWidget'
import StatusIconWidget from '@/components/layouts/StatusIconWidget'
import { useIdeaBoxFilterStore } from '@/stores/IdeaBoxFilterStore'

export default {
    name: 'IdeaBoxIndex',
    components: {
        toolbar,
        loading,
        AppFilterApplyButton,
        AppFilterSearchField,
        AppFilterDatePicker,
        ContentFilterEmployee,
        UserFilterWidget,
        SelectStatusFilterWidget,
        StatusIconWidget
    },
    setup() {
        const filterStore = useIdeaBoxFilterStore()
        return { filterStore }
    },
    data() {
        return {
            loading: false,
            totalItems: 0,
            items: [],
            options: {},
            dataLoaded: false
        }
    },
    watch: {
      options: {
        handler() {
          this.getIdeas()
        },
        deep: true,
      }
    },
    methods: {
        t: function (key) {
            return this.$t('ideabox.list.' + key)
        },
        reload () {
            this.items = []
            this.getIdeas()
        },
        applyFilter(doReload = true) {
            this.filterStore.filterApplied()
            this.reload()
        },
        getIdeas() {
            this.loading = true
            this.dataLoaded = false

            this.apiCall().then(data => {
                this.items = data.items
                this.totalItems = data.total
                this.loading = false
                this.dataLoaded = true
            })
        },
        apiCall() {
            return new Promise((resolve, reject) => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options

                let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

                HTTP.get('ideabox' + url).then(function (response) {

                    let items = response.data.data
                    let total = response.data.total
               
                    resolve({
                        items,
                        total,
                    })
                }.bind(this))
            })
        },
        prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
            let queryStringParts = []
            if (this.filterStore.filter.searchTerm) {
                queryStringParts.push('q=' + this.filterStore.filter.searchTerm)
            }

            if (this.filterStore.filter.dateStart) {
                queryStringParts.push('dateStart=' + this.filterStore.filter.dateStart)
            }

            if (this.filterStore.filter.dateEnd) {
                queryStringParts.push('dateEnd=' + this.filterStore.filter.dateEnd)
            }

            if (this.filterStore.filter.status.length > 0) {
                queryStringParts.push('st=' + this.filterStore.filter.status)
            }

            if (this.filterStore.filter.user != null) {
                queryStringParts.push('user_id=' + this.filterStore.filter.user.id)
            }

            if (sortBy) {
                queryStringParts.push('sortBy=' + (sortBy[0] ?? ''))
                let direction = sortDesc[0] ? 'desc' : 'asc'
                queryStringParts.push('direction=' + direction)
            }

            page = page ? page : 1
            queryStringParts.push('page=' + page)
            if (itemsPerPage != null) {
                queryStringParts.push('itemsPerPage=' + itemsPerPage)
            }
            return '?' + queryStringParts.join('&')
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: this.t('table.headers.subject'),
                    align: 'start',
                    sortable: false,
                    value: 'subject',
                    cellClass: "text-middle"
                },
                {
                    text: this.t('table.headers.author'),
                    align: 'start',
                    sortable: false,
                    value: 'author_name',
                    width: 200,
                    cellClass: "text-middle"
                },
                {
                    text: this.t('table.headers.date'),
                    align: 'start',
                    sortable: true,
                    value: 'created_at',
                    width: 140,
                    cellClass: "text-middle"
                },
                {
                    text: this.t('table.headers.status'),
                    align: 'start',
                    sortable: false,
                    value: 'status',
                    width: 140,
                    cellClass: "text-middle"
                },
                {
                    text: this.t('table.headers.edit'),
                    align: 'center',
                    sortable: false,
                    value: 'edit',
                    width: 100
                }
            ]
        }
    }
}
</script>

<style scoped lang="scss">
.anonymous {
    color: #B2B2B2;
}

.ellipsis-wrapper {
    height: 100%;
    min-width: 320px;

    .ellipsis {
        position: absolute;
        top: 18px;
        width: 100%;
        padding-right: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
