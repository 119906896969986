<template>
  <div>
 
    <BasicNewContainer
      :title="t('create.title')"
      ref="editor"
      @cancel="cancel"
      :saveAction="saveItem"
      @saved="close"
      :has-changes="hasChanges" 
    >
      <template v-slot:default>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <salutation-select
              v-model="data.salutation"
            ></salutation-select>

            <v-text-field
              filled
              :label="t('details.firstname') + '*'"
              counter="120"
              v-model="data.firstname"
              :rules="[
								() => !!data.firstname || $t('validation.required.firstname'),
								() => (data.firstname && data.firstname.length <= 120) || $t('validation.length.default')
							]"
            ></v-text-field>

            <v-text-field
              filled
              :label="t('details.lastname') + '*'"
              counter="120"
              v-model="data.name"
              :rules="[
                () => !!data.name || $t('validation.required.lastname'),
                () => (data.name && data.name.length <= 120) || $t('validation.length.default')
              ]"
            ></v-text-field>

            <v-text-field
              filled
              :label="t('details.staff-number')"
              v-model="data.staff_number"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              filled
              :label="t('details.email')"
              v-model="data.email"
              autocomplete="off"
              :rules="emailRules"
              hide-details="auto"
            ></v-text-field>

            <v-text-field
              class="mt-4"
              filled
              :label="t('details.phone')"
              v-model="data.phone_number"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              filled
              :label="t('details.phone-mobile')"
              v-model="data.mobile_phone_number"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              filled
              :label="t('details.phone-emergency')"
              v-model="data.emergency_phone_number"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              filled
              :label="t('details.workplace')"
              v-model="data.workplace"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              filled
              :label="t('details.profession')"
              v-model="data.profession"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              filled
              :label="t('details.bank')"
              v-model="data.bank"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              filled
              :label="t('details.iban')"
              v-model="data.iban"
              hide-details
            ></v-text-field>

            <app-filter-date-picker
              class="mt-4"
              :filterLabel="t('details.birth_date')"
              v-model="data.birth_date"
              id="dateStart"
              :clearable="true"
            ></app-filter-date-picker>

            <v-textarea
              class="mt-4 mb-4"
              filled
              :label="t('details.notes')"
              v-model="data.notes"
              hide-details
            ></v-textarea>

            <h4>{{ t('details.cooperation') }}</h4>

            <v-row class="mt-0">
              <v-col class="pr-2">
                <app-filter-date-picker
                  :filterLabel="t('details.begin_date')"
                  v-model="data.begin_date"
                  id="dateStart"
                  :clearable="true"
                ></app-filter-date-picker>
              </v-col>

              <v-col class="pl-2">
                <app-filter-date-picker
                  :filterLabel="t('details.end_date')"
                  v-model="data.end_date"
                  id="dateStart"
                  :clearable="true"
                ></app-filter-date-picker>
              </v-col>
            </v-row>

            <v-checkbox
              v-if="moduleEnabled('phonebook')"
              :label="t('details.phonebook-visible')"
              class="mb-0"
              v-model="data.phonebook_hidden"
              :true-value="0"
              :false-value="1"
              hide-details
            ></v-checkbox>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('avatar.group-title') }}</v-card-title>
          <v-card-text>
            <PictureUpload :deletion=true v-model="data.picture" aspectMode="quadratic"/>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('address.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              filled
              :label="t('address.address_line_1')"
              v-model="data.address.address_line_1"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              filled
              :label="t('address.address_line_2')"
              v-model="data.address.address_line_2"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              filled
              :label="t('address.zip_code')"
              v-model="data.address.zip_code"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              filled
              :label="t('address.place')"
              v-model="data.address.place"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              filled
              :label="t('address.country')"
              v-model="data.address.country"
              hide-details
            ></v-text-field>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ t('login-data.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              filled
              :label="t('login-data.username') + '*'"
              counter="120"
              v-model="data.username"
              autocomplete="off"
              @keyup="lowercase"
              :spellcheck="false"
              :rules="[
								() => !!data.username || $t('validation.required.username'),
								() => (data.username && data.username.length <= 120) || $t('validation.length.default'),
								() => !/\s/.test(data.username) || $t('errors.rules.no-spaces')
							]"
            ></v-text-field>

            <v-text-field
              filled
              :label="t('login-data.password')"
              v-model="data.password"
              autocomplete="off"
              type="password"
              hide-details
            ></v-text-field>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('user-roles.group-title') }}</v-card-title>
          <v-card-text>
            <v-radio-group v-model="data.role">
              <v-radio v-show="isAdmin()" :label="$t('users.data.roles.admin')" value="admin"/>
              <v-radio :label="$t('users.data.roles.coordinator')" value="coordinator"/>
              <v-radio :label="$t('users.data.roles.employee')" value="employee"/>
              <v-radio :label="$t('users.data.roles.customer')" value="customer"/>
              <v-radio :label="$t('users.data.roles.none')" value="none"/>
            </v-radio-group>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('admin.group-title') }}</v-card-title>
          <v-card-text>
              <user-filter-widget
                :label="t('admin.manager')"
                v-model="data.manager"
              ></user-filter-widget>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('access-rights.group-title') }}</v-card-title>
          <v-card-text>
            <div>
              <h4>{{ t('access-rights.permission-group.title') }}</h4>

              <permission-group-selector
                class="mt-2"
                v-model="data.assigned.permission_groups"
                :required="false"
                mode="dropdown"
              ></permission-group-selector>

              <v-divider class="mt-2 mb-4"></v-divider>

              <h4>{{ t('access-rights.customers.title') }}</h4>
              <div>{{ t('access-rights.customers.hint') }}</div>
              <div>
                  <v-text-field 
                    class="mt-2 mb-3"
                    type="text" 
                    v-model="searchCustomers"
                    :label="t('access-rights.customers.search')"
                    append-icon="mdi-magnify"
                    filled
                    hide-details
                  ></v-text-field>
              </div>

              <div class="search-items-wrapper mb-4">
                <div class="search-items" v-for="item in customers" :key="item.id"
                    @click="selectItem('customers', item)" v-if="searchCustomers !== ''"> {{ item.display_name }}
                </div>
                <v-list v-if="data.assigned.customers.length > 0" lines="one" class="assigned-items-list">
                  <v-list-item
                    v-for="(item, index) in data.assigned.customers"
                    class="employee-element passive"
                    :key="index"
                  >
                    <span class="ellipsis"><v-icon>mdi-account-lock-open-outline</v-icon>{{ item.name != null ? item.name : item.lastname + ', ' + item.firstname }}</span>
                    <v-icon class="employee-action" color="primary" @click.native="removeItem('customers', index)">mdi-minus-circle</v-icon>
                  </v-list-item>
                </v-list>
              </div>

              <v-divider class="mt-2 mb-4"></v-divider>

              <h4>{{ t('access-rights.customer-projects.title') }}</h4>
              <div>{{ t('access-rights.customer-projects.hint') }}</div>

              <CustomerProjectSelector class="mt-2 mb-4" @item-selected="onCustomerProjectSelected"/>
              <v-list
                v-if="data.customer_projects_ids.length > 0"
                subheader
                two-line
                class="assigned-items-list"
                :class="[{ 'mb-4': data.customer_projects_ids.length > 0 }]"
              >
                <v-list-item
                  v-for="(item, index) in data.customer_projects_ids"
                  :key="index"
                  class="employee-element passive"
                >
                  <v-list-item-avatar>
                    <v-icon>mdi-account-lock-open-outline</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ customer_projects[item].name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ customer_projects[item].customer.name }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-icon class="employee-action two-line" color="primary" @click.native="data.customer_projects_ids.splice(index, 1)">mdi-minus-circle</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-divider v-if="moduleEnabled('quality_report')" class="mt-2 mb-4"></v-divider>

              <div v-if="moduleEnabled('quality_report')">
                <h4>{{ t('access-rights.checklists.title') }}</h4>
                <div>{{ t('access-rights.checklists.hint') }}</div>

                <v-text-field
                  class="mt-2"
                  :class="[{ 'mb-3': data.assigned.checklists.length > 0 }]"
                  :label="t('access-rights.checklists.search')"
                  append-icon="mdi-magnify"
                  filled
                  v-model="searchChecklists"
                  hide-details
                >
                </v-text-field>

                <div class="search-items-wrapper">
                  <div class="search-items mt-3" v-for="(item) in checklists" :key="item.id"
                      @click="selectItem('checklists', item)" v-if="searchChecklists !== ''"> {{ item.name }}
                  </div>
                  <v-list v-if="data.assigned.checklists.length > 0" lines="one" class="assigned-users-list">
                    <v-list-item
                      v-for="(item, index) in data.assigned.checklists"
                      :key="index"
                      class="employee-element passive"
                    >
                      <span class="ellipsis"><v-icon>mdi-account-lock-open-outline</v-icon>{{ item.name }}</span>
                      <v-icon class="employee-action" color="primary" @click.native="removeItem('checklists', index)">mdi-minus-circle</v-icon>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </BasicNewContainer>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import store from 'store'
import { hasModule } from '@/services/LicenseService'
import axios from 'axios'
import _ from 'lodash'
import PictureUpload from '@/components/inputs/PictureUpload'
import BasicNewContainer from '@/components/inputs/BasicNewContainer'
import CustomerProjectSelector from '@/components/CustomerProjectSelector'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import SalutationSelect from '@/components/SalutationSelect'
import PermissionGroupSelector from '@/components/PermissionGroupSelector.vue'
import UserFilterWidget from '@/components/filter-components/UserFilterWidget'

export default {
  name: 'UsersCreate',
  components: {
    PermissionGroupSelector,
    CustomerProjectSelector,
    BasicNewContainer,
    PictureUpload,
    AppFilterDatePicker,
    SalutationSelect,
    UserFilterWidget
  },
  data() {
    return {
      dataOriginal: null,
      data: {
        role: 'admin',
        assigned: {
          groups: [],
          customers: [],
          checklists: [],
          permission_groups: []
        },
        notifications: {
          groups: [],
          customers: [],
          checklists: []
        },
        picture: null,
        workplace: '',
        profession: '',
        phonebook_hidden: 0,
        address: {
          address_line_1: null,
          address_line_2: null,
          zip_code: null,
          place: null,
          country: null
        },
        salutation: '',
        bank: '',
        iban: '',
        birth_date: '',
        notes: '',
        begin_date: '',
        end_date: '',
        email: '',
        customer_projects_ids: []
      },
      manager: null,
      customer_projects: {},
      searchGroups: '',
      searchCustomers: '',
      searchChecklists: '',
      searchGroupsNotifications: '',
      searchCustomersNotifications: '',
      searchChecklistsNotifications: '',
      groups: null,
      permissionGroups: [],
      customers: null,
      checklists: [],
      groupsNotifications: null,
      customersNotifications: null,
      checklistsNotifications: null,
      error: null,
      cancelHTTP: null,
      errorCancel: null,
      loginUser: null
    }
  },
  props: {
    userData: null
  },
  created() {
    this.dataOriginal = JSON.parse(JSON.stringify(this.data))
    this.getPermissionGroups()
    this.loginUser = store.get('user')

    const queryParams = this.parseQueryParams(window.location.search)

    if (typeof queryParams.customer_id !== "undefined") {
      this.data.role = "employee";

      HTTP.get('client-admin-id').then(function (response) {
        this.data.assigned.permission_groups = [response.data]
      }.bind(this)).catch(function (error) {
        this.error = error
      }.bind(this))
    }
  },
  watch: {
    searchGroups: function (val) {
      this.getSearchResultGroups(encodeURIComponent(val))
    },
    searchCustomers: function (val) {
      this.getSearchResultCustomers(encodeURIComponent(val))
    },
    searchChecklists: function (val) {
      this.getSearchResultChecklists(encodeURIComponent(val))
    },
    searchGroupsNotifications: function (val) {
      this.getSearchResultGroups(encodeURIComponent(val), true)
    },
    searchCustomersNotifications: function (val) {
      this.getSearchResultCustomers(encodeURIComponent(val), true)
    },
    searchChecklistsNotifications: function (val) {
      this.getSearchResultChecklists(encodeURIComponent(val), true)
    }
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    },
    emailRules() {
      if (this.data.email == "") {
        return [];
      }
      return [
        v => /.+@.+/.test(v) || this.$t('validation.valid.email'),
      ]
    },
  },
  methods: {
    t: function (key) {
      return this.$t('users.detail.' + key)
    },
    lowercase() {
      this.data.username = this.data.username.toLowerCase()
    },
    moduleEnabled(string) {
      return hasModule(string)
    },
    onCustomerProjectSelected(id, data) {
      if (!this.data.customer_projects_ids.includes(id)) {
        this.data.customer_projects_ids.push(id)
        this.customer_projects[id] = data
      }
    },
    getPermissionGroups() {
      HTTP.get('permission-groups').then(function (response) {
        this.permissionGroups = response.data.data
      }.bind(this)).catch(function (error) {
        this.error = error
      }.bind(this))
    },
    getSearchResultGroups(val, notifications = false) {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('groups?q=' + val, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        if (!notifications) {
          this.groups = response.data
        } else {
          this.groupsNotifications = response.data
        }
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    getSearchResultCustomers(val, notifications = false) {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('customers?q=' + val, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        if (!notifications) {
          this.customers = response.data.data
        } else {
          this.customersNotifications = response.data.data
        }
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    getSearchResultChecklists(val, notifications = false) {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('checklists?q=' + val, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        if (!notifications) {
          this.checklists = response.data.data
        } else {
          this.checklistsNotifications = response.data.data
        }
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    selectItem(type, item, notifications = false) {
      if (!notifications) {
        this.data.assigned[type].push(item)
        this.searchGroups = ''
        this.searchCustomers = ''
        this.searchChecklists = ''
        this.checkDuplicates(type)
      } else {
        this.data.notifications[type].push(item)
        this.searchGroupsNotifications = ''
        this.searchCustomersNotifications = ''
        this.searchChecklistsNotifications = ''
        this.checkDuplicates(type, true)
      }
    },
    isAdmin() {
      return this.loginUser && this.loginUser.role === "admin"
    },
    removeItem(type, index, notifications = false) {
      if (notifications) {
        this.data.notifications[type].splice(index, 1)
      } else {
        this.data.assigned[type].splice(index, 1)
      }
    },
    checkDuplicates(type, notifications = false) {
      if (notifications) {
        this.data.notifications[type] = _.uniqBy(this.data.notifications[type], 'id')
      } else {
        this.data.assigned[type] = _.uniqBy(this.data.assigned[type], 'id')
      }
    },
    inArray(needle, haystack) {
      var length = haystack.length
      for (var i = 0; i < length; i++) {
        if (haystack[i] === needle) {
          return true
        }
      }
      return false
    },
    close() {
      this.$router.push({ name: 'Users' })
    },
    cancel() {
      this.close()
    },
    parseQueryParams(url) {
      const params = {}
      const queryString = url.split('?')[1]

      if (queryString) {
        const keyValuePairs = queryString.split('&')

        keyValuePairs.forEach(keyValuePair => {
          const [key, value] = keyValuePair.split('=')
          const decodedKey = decodeURIComponent(key)
          const decodedValue = decodeURIComponent(value || '')
          params[decodedKey] = decodedValue
        });
      }

      return params
    },
    saveItem() {
      let path = 'users'

      const queryParams = this.parseQueryParams(window.location.search)

      if (typeof queryParams.customer_id !== "undefined") {
        path += "?customer_id=" + queryParams.customer_id
      }

      if (this.data.manager) {
        this.data.manager_id = this.data.manager.id
      }
      return HTTP.post(path, this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
.assigned-items-list > div {
  padding: 10px;
  min-height: auto;
}

.employee-element {

  .employee-action.two-line {
    top: 8px;
  }

  .v-list-item__avatar {
    margin: 0 8px 0 0 !important;
    width: 24px !important;
    height: 24px !important;
    min-width: auto !important;
    border-radius: 0;
  }

  .v-list-item__content {
    padding: 0;
  }
}
</style>
