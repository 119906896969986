<template>
  <div class="has-hero-container">
    <div>	
		  <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>
	  
		  <div class="sp-hero sp-bg-light-blue">
    
			  <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">
			
          <app-filter-apply-button
            v-if="filterStore.hasChanges"
            @click="applyFilter(); options.page = 1"
          ></app-filter-apply-button>
        
          <v-slide-group show-arrows>
		
					  <v-slide-item>
					
              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <app-filter-search-field
                      v-model="filterStore.filter.searchTerm"
                      :filterLabel="t('filter.name-search')"
                      clearable
                      append-icon="mdi-magnify"									
                    ></app-filter-search-field>
                  </div>
                </div>
              </div>
						
					  </v-slide-item>

            <v-slide-item>
					
              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <content-filter-customer
                      v-model="filterStore.filter.customer"
                      :label="t('filter.customer-search')"
                      :get-search-url="getCustomersSearchUrl"
                      :preselected="filterStore.filter.customer ? filterStore.filter.customer : null"
                      clearable
                      append-icon="mdi-magnify"									
                    ></content-filter-customer>
                  </div>
                </div>
              </div>
						
					  </v-slide-item>

            <v-slide-item>

              <div class="filter-toggle">
                <div class="filter-item">
                  <h4>{{ t('filter.inactive-label') }}</h4>
                  <v-switch
                    v-model="filterStore.filter.showInactive"
                    :label="filterStore.filter.showInactive ? t('filter.inactive-state.yes') : t('filter.inactive-state.no')"
                    color="primary"
                    hide-details
                  ></v-switch>
                </div>
              </div>

            </v-slide-item>
						
				  </v-slide-group>

			  </v-container>
			
		  </div>
		
      <v-container fluid>  

        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            :to="{ name: 'ChecklistCreateChecklist' }"
          >
            <v-icon>mdi-plus</v-icon>
            {{ t('create') }}
          </v-btn>
        </v-row>

        <v-row v-if="dataLoaded && checklists.data.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
            <loading v-if="loading"></loading>
          </div>
        </v-row>

        <v-row v-else>

          <v-data-table
            :headers="headers"
            :items="checklists.data"
            :options.sync="options"
            :server-items-length="dataTotal"
            :loading="loading"
            class="elevation-0"
            :sort-by.sync="filterStore.table.sortBy"
            :sort-desc.sync="filterStore.table.sortDesc"
            :page.sync="filterStore.table.currentPage"
            :items-per-page.sync="filterStore.table.itemsPerPage"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.name`]="{ item }">
              <span class="ellipsis"><b>{{ item.name }}</b><span class="checklist-inactive" v-if="!item.active">[ inaktiv ]</span></span>
              <br>
              <span class="ellipsis preview-text">{{ formatDateTime(item.updated_at) }}</span>
            </template>
            <template v-slot:[`item.customer`]="{ item }">
              <span class="ellipsis">{{ item.customer.name }}</span>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <router-link :to="{ name: 'ChecklistEditChecklist', params: { id: item.id } }" class="edit-row-table">
                <v-icon
                    small
                    class="edit-row-button"
                >
                  edit
                </v-icon>
              </router-link>
            </template>
            <template v-slot:[`item.copy`]="{ item }">
              <router-link :to="{ name: 'ChecklistCreateChecklist', query: { fromID: item.id} }" class="edit-row-table">
                <v-icon
                    v-if="item.is_template"
                    small
                    class="edit-row-button"
                >
                  content_copy
                </v-icon>
              </router-link>
            </template>
          </v-data-table>

        </v-row>

		  </v-container>

    </div>
  </div>
</template>

<script>
  import { HTTP } from './../auth'
  import toolbar from '../components/layouts/Navigation.vue'
  import loading from '@/components/layouts/Loading.vue'
  import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
  import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
  import ContentFilterCustomer from '@/components/filter-components/ContentFilterCustomer'
  import { useChecklistFilterStore } from '@/stores/ChecklistFilterStore'

  export default {
	  name: 'ChecklistsIndex',
    components: {
      toolbar,
      loading,
      AppFilterApplyButton,
	    AppFilterSearchField,
      ContentFilterCustomer
    },
    setup() {
      const filterStore = useChecklistFilterStore()
      return { filterStore }
    },
    data () {
      return {
        loading: false,
        checklists: {
          page: 1,
          current_page: 1,
          last_page: 1,
          total: null,
          data: []
        },
        processingCopy: false,
        dataLoaded: false,
        error: false,
        dataTotal: 0,
        options: {},
        showInactive: false
      }
    },
    created() {
      this.applyFilter(false)
    },
    computed: {
      headers() {
        return [
          {
            text: this.t('table.headers.name'),
            align: 'start',
            sortable: true,
            value: 'name'
          },
          {
            text: this.t('table.headers.customer'),
            align: 'start',
            sortable: false,
            value: 'customer'
          },
          {
            text: this.t('table.headers.edit'),
            value: 'edit',
            sortable: false,
            align: 'center',
            width: 150,
          },
          {
            text: this.t('table.headers.copy'),
            value: 'copy',
            sortable: false,
            align: 'center',
            width: 150,
          }
        ]
      }
    },
    watch: {
      options: {
        handler() {
          this.getChecklists()
        },
        deep: true,
      }
    },
    methods: {
      t: function (key) {
        return this.$t('checklists.list.' + key);
      },
      reload () {
        this.$nextTick(function () {
          this.getChecklists()
        }.bind(this))
      },
      applyFilter(doReload = true) {
        this.filterStore.filterApplied()
        if (doReload) {
          this.reload()
        }
      },
      prepareUrl() {
        let queryStringParts = []
        if (this.filterStore.filter.searchTerm) {
          queryStringParts.push('q=' + this.filterStore.filter.searchTerm)
        }
        if (this.filterStore.filter.customer) {
          queryStringParts.push('c=' + this.filterStore.filter.customer.id)
        }
        queryStringParts.push('i=' + this.filterStore.filter.showInactive)
        return '?' + queryStringParts.join('&')
      },
      getChecklists () {
        this.loading = true

        let url = this.prepareUrl()

        HTTP.get('checklists' + url, {params: this.options})
          .then(function (response) {
            this.checklists.data = response.data.data
            this.checklists.last_page = response.data.last_page
            this.checklists.current_page = response.data.current_page
            this.checklists.total = response.data.total
            this.dataTotal = response.data.total
            this.error = false
            this.dataLoaded = true
            this.loading = false
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
            this.loading = false
          }
        }.bind(this))
      },
      getCustomersSearchUrl(val) {
          return 'checklists/getCustomers?q=' + val
      },
      copyChecklist() {
        this.processingCopy = true
      }
    }
  }
</script>
