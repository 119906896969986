import {defineStore, acceptHMRUpdate} from 'pinia'
import moment from "moment/moment";

const store = defineStore('CustomerNotesFilterStore', {
    state: () => {
        return {
            filter: {
                searchTerm: null,
                workStart: moment().subtract(28, 'days').format('YYYY-MM-DD'),
                workEnd: moment().format('YYYY-MM-DD'),
                status: [],
            },
            appliedFilter: '',
            table: {
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: ['name'],
                sortDesc: [false]
            },
        }
    },
    getters: {
        hasChanges: (state) => {
            return JSON.stringify(state.filter) !== JSON.stringify(state.appliedFilter)
        }
    },
    actions: {
        filterApplied() {
            this.appliedFilter = {...this.filter}
        }
    },
    persist: true,
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
}

export const useCustomerNotesFilterStore = store

