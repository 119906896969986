<template>
  <div>

    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>


    <div class="sp-hero sp-bg-light-blue">
      <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

        <app-filter-apply-refresh-button
            :has-changes="filterStore.hasChanges"
            :is-loading="loading"
            @click="applyFilter();  options.page = 1"
        />

        <v-slide-group show-arrows>

          <v-slide-item>
            <div class="filter-item">
              <v-checkbox
                  v-model="filterStore.filter.withInactive"
                  :label="$t('expense-type.list.filter.include-inactive')"
              />
            </div>
          </v-slide-item>
        </v-slide-group>
      </v-container>
    </div>

    <v-container fluid class="edit-screen no-hero-container">

      <v-row class="data-table-toolbar">
        <v-spacer></v-spacer>
        <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            :to="{ path: '/settings/expense-types/create'}"
        >
          <v-icon>mdi-plus</v-icon>
          {{ t('create') }}
        </v-btn>
      </v-row>

      <v-row v-if="dataLoaded && totalItems === 0">
        <div class="empty-datatable">
          <v-icon>mdi-alert-circle-outline</v-icon>
          {{ t('no-data') }}
        </div>
      </v-row>

      <v-row v-if="dataLoaded && items.length > 0">
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            class="elevation-0"
            :sort-by="initialSortBy"
            :sort-desc="initialSortDesc"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
        >
          <v-progress-linear v-show="loading" slot="progress" color="primary" indeterminate></v-progress-linear>

          <template v-slot:[`item.name`]="{ item }">
            <span v-if="item.name" class="ellipsis"><b>{{ item.name }}</b></span>
            <span v-else class="ellipsis"><b>{{ t('expenses.data.type.' + item.name_code) }}</b></span>
          </template>

          <template v-slot:[`item.edit`]="{ item }">

            <router-link :to="{ path: '/settings/expense-types/' + item.id }" class="edit-row-table">
              <v-icon
                  small
                  class="edit-row-button"
              >
                edit
              </v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import  {HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton.vue'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField.vue'
import AppFilterApplyRefreshButton from '@/components/vuetify/AppFilterApplyRefreshButton'
import { useExpenseTypeFilterStore } from '@/stores/ExpenseTypeFilterStore'

export default {
  name: 'ExpenseTypesIndex',
  components: {
    AppFilterApplyRefreshButton,
    AppFilterSearchField, AppFilterApplyButton,
    toolbar,
  },
  setup() {
    const filterStore = useExpenseTypeFilterStore()
    return { filterStore }
  },
  data() {
    return {
      items: null,
      totalItems: null,
      dataLoaded: false,
      loading: false,
      error: false,
      options: {
        itemsPerPage: 25
      },
      initialSortBy: 'name',
      initialSortDesc: false,
    }
  },
  async created() {
    await this.applyFilter()
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getItems()
      },
      deep: true,
    }
  },
  methods: {
    t: function (key) {
      return this.$t('expense-type.list.' + key)
    },
    async applyFilter() {
      this.filterStore.table.currentPage = 1
      this.filterStore.filterApplied()
      await this.getItems()
    },
    async getItems() {
      this.loading = true
      try {
        let response = await HTTP.get('expense-types?includeInactive=' + this.filterStore.filter.withInactive, {params: this.options})
        this.items = response.data.data
        this.totalItems = response.data.total
        this.dataLoaded = true
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
