import EmergencyContacts from '@/views/EmergencyContacts'

const emergencyContactsRoutes = [
    {
        path: '/emergency-contacts',
        name: 'EmergencyContacts',
        component: EmergencyContacts,
        meta: {
            accessConfig:  {
                moduleName: ['emergency_contacts'],
                requiresAuth: true
            }
        }
    }
]

export default emergencyContactsRoutes
